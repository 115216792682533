<template>
  <v-form @submit.stop.prevent="submit()" ref="form" class="mt-5">
    <v-row>
      <v-col>
        <v-select
          :items="Partners"
          item-text="name"
          hide-details
          dense
          :label="$tc('partner', 1)"
          v-model="partnerSelected"
          return-object
          outlined
          @change="getInvestmentConditions()"
        >
        </v-select>
      </v-col>
      <v-col>
        <v-select
          :items="languageOptions"
          item-text="text"
          item-value="value"
          dense
          :label="$tc('language')"
          v-model="language"
          outlined
          @change="getInvestmentConditions()"
        ></v-select>
      </v-col>
      <v-col>
        <v-btn
          class="dxa_modal_btnError"
          color="red"
          min-width="100"
          @click="deleteInvestmentConditions()"
        >
          <v-icon small>mdi-trash-can</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-textarea
      auto-grow
      outlined
      :label="$t('introduction')"
      rows="5"
      v-model="investmentConditions.intro"
      @change="$emit('alert')"
    ></v-textarea>

    <h4 class="h4">{{ $t("text") }}:</h4>

    <quill-editor
      ref="myQuillEditor"
      v-model="investmentConditions.text"
      @change="$emit('alert')"
    />

    <!-- <v-textarea
      auto-grow
      outlined
      :label="$t('text')"
      class="mt-6"
      rows="7"
      v-model="InvestmentConditions.Text"
    ></v-textarea> -->

    <v-card-actions class="pl-0 dxa_modal_actions">
      <v-btn
        class="dxa_modal_btnSuccess"
        color="primary"
        min-width="100"
        :loading="loading"
        type="submit"
        >{{ $t("save") }}</v-btn
      >
      <v-btn
        class="dxa_modal_btnError"
        color="secondary"
        min-width="100"
        @click="$router.go(-1)"
        >{{ $t("go_back") }}</v-btn
      >
    </v-card-actions>
  </v-form>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import "quill/dist/quill.bubble.css"; // for bubble theme
import { quillEditor } from "vue-quill-editor";
import { LanguageEnum } from "@/shared/enums/LanguageEnum";
export default {
  name: "ManageCompanyInvestmentConditions",

  data: () => ({
    gs: new GeneralServices(),
    apiService: new ApiService(),
    loading: false,
    company_id: null,
    partnerSelected: null,
    investmentConditions: {
      text: null,
      intro: null,
      id: null,
    },
    languageOptions: [
      {
        text: "PT",
        value: LanguageEnum.pt,
      },
      {
        text: "EN",
        value: LanguageEnum.en,
      },
    ],
    language: LanguageEnum.pt,
  }),
  components: {
    // Editor,
    quillEditor,
  },

  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  props: {
    BriefingId: Number,
    Partners: Array,
    company_prop: Object,
  },

  async created() {
    this.partnerSelected = this.Partners[0];
    await this.getInvestmentConditions();
  },

  methods: {
    submit: async function () {
      if (
        this.loading ||
        (!this.investmentConditions.text && !this.investmentConditions.intro)
      ) {
        return;
      }
      this.loading = true;
      this.error = null;

      this.$emit("alert");

      this.company_prop.InvestmentConditionsIntro =
        this.investmentConditions.intro;
      this.company_prop.InvestmentConditionsText =
        this.investmentConditions.text;

      if (!this.investmentConditions.id) {
        await this.createInvestmentConditions();
      } else {
        await this.updateInvestmentConditions();
      }

      this.loading = false;
    },

    getInvestmentConditions: async function () {
      this.$emit("alert");

      await this.apiService
        .getRequest(
          `companybriefing/investment-conditions/${this.BriefingId}/${this.language}/${this.partnerSelected.id}`
        )
        .then((res) => {
          this.investmentConditions.intro =
            res.content?.investmentConditionsIntro;
          this.investmentConditions.text =
            res.content?.investmentConditionsText;
          this.investmentConditions.id = res.content?.id;
        })
        .catch((error) => {
          this.$emit("alert", false, error);
        });
    },

    createInvestmentConditions: async function () {
      await this.apiService
        .postRequest("companybriefing/investment-conditions", {
          BriefingId: this.BriefingId,
          language: this.language,
          PartnerB2bId: this.partnerSelected.id,
          InvestmentConditionsText: this.investmentConditions.text,
          InvestmentConditionsIntro: this.investmentConditions.intro,
        })
        .then((res) => {
          this.investmentConditions.id = res.content.id;
          this.$emit("alert", true, this.$t("saved"));
        })
        .catch((error) => {
          this.$emit("alert", false, error);
        });
    },

    updateInvestmentConditions: async function () {
      await this.apiService
        .putRequest("companybriefing/investment-conditions", {
          Id: this.investmentConditions.id,
          InvestmentConditionsText: this.investmentConditions.text,
          InvestmentConditionsIntro: this.investmentConditions.intro,
        })
        .then(() => {
          this.$emit("alert", true, this.$t("saved"));
        })
        .catch((error) => {
          this.$emit("alert", false, error);
        });
    },

    deleteInvestmentConditions: async function () {
      if (!this.investmentConditions.id) return;
      this.loading = true;

      this.$emit("alert");

      await this.apiService
        .deleteRequest(
          `companybriefing/investment-conditions/${this.investmentConditions.id}`
        )
        .then(() => {
          this.investmentConditions = {
            id: null,
            intro: null,
            text: null,
          };
          this.$emit("alert", true, this.$t("saved"));
        })
        .catch((error) => {
          this.$emit("alert", false, error);
        });
      this.loading = false;
    },
  },
};
</script>
